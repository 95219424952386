<template>
  <div class="sticky-top">
    <div class="frontNb" :class="{ active: isActive }">
      <router-link to="/">
        <h1 class="frontNb-logo">全台新</h1>
      </router-link>
      <button
        type="button"
        class="frontNb-toggle ml-auto"
        @click="isActive = !isActive"
      >
        <i class="fas fa-bars"></i>
      </button>

      <nav class="frontNb-collapse" :class="{ active: isActive }">
        <ul class="frontNb-nav">
          <li
            class="frontNb-nav-item"
            :class="{ active: currentPage === 'Home' }"
          >
            <router-link
              class="frontNb-nav-item-link"
              to="/home"
              @click="navbarHide('Home')"
              >首頁</router-link
            >
          </li>
          <li
            class="frontNb-nav-item"
            :class="{ active: currentPage === 'ProductList' }"
          >
            <router-link
              class="frontNb-nav-item-link"
              to="/productList/all"
              @click="navbarHide('ProductList')"
              >產品介紹</router-link
            >
          </li>
          <li
            class="frontNb-nav-item"
            :class="{ active: currentPage === 'Contact' }"
          >
            <router-link
              class="frontNb-nav-item-link"
              to="/contact"
              @click="navbarHide('Contact')"
              >聯絡我們</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "navbar",
  setup() {
    const route = useRoute();
    const currentPage = ref();
    currentPage.value = route.name;
    const isActive = ref(false);

    const navbarHide = (page) => {
      isActive.value = false;
      currentPage.value = page;
    };
    return {
      isActive,
      currentPage,
      navbarHide,
    };
  },
};
</script>
