<template>
  <div>
    <header>
      <Navbar />
    </header>
    <main style="min-height: calc(100vh - 80px - 52px)">
      <router-view></router-view>
      <LightBox />
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "components/front/Navbar";
import Footer from "components/front/Footer";
import LightBox from "components/front/LightBox";

export default {
  name: "frontLayout",
  components: {
    Navbar,
    Footer,
    LightBox,
  },
};
</script>
