<template>
  <footer class="footer">
    <div class="container d-flex justify-content-between align-items-center">
      <h6 class="h6 mb-0">
        <a class="text-dark" href="mailto:a85752958@gmail.com">聯絡我們</a>
      </h6>
      <h6 class="h6 mb-0">
        Copyright © 2022 chiuan tai shin All rights reserved
      </h6>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
