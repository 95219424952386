<template>
  <div class="lightBox" :class="{ active: isActive }">
    <div class="lightBox-wrapper" @click.self="close">
      <div
        class="lightBox-img"
        :class="{ active: isActive }"
        :style="{
          'background-image': `url(${imgUrl})`,
          transform: `rotate(calc(90deg * ${rotateCount})) scale(${scaleCount})`,
        }"
      ></div>
      <div class="lightBox-btnGroup">
        <button
          type="button"
          class="lightBox-btnGroup-btn"
          data-control="scaleDown"
          @click="control"
        >
          <i class="fas fa-search-minus"></i>
        </button>
        <button
          type="button"
          class="lightBox-btnGroup-btn"
          data-control="scaleUp"
          @click="control"
        >
          <i class="fas fa-search-plus"></i>
        </button>
        <button
          type="button"
          class="lightBox-btnGroup-btn"
          data-control="rotate-left"
          @click="control"
        >
          <i class="fas fa-redo-alt"></i>
        </button>
        <button
          type="button"
          class="lightBox-btnGroup-btn"
          data-control="rotate-right"
          @click="control"
        >
          <i class="fas fa-undo-alt"></i>
        </button>
      </div>
      <button type="button" class="lightBox-close" @click.capture="close">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, onBeforeUnmount } from "vue";
export default {
  name: "lightbox",
  setup() {
    const mitt = inject("mitt");
    //lightbox
    const imgUrl = ref("");
    onMounted(() => {
      mitt.on("lightBox", (url) => {
        isActive.value = true;
        imgUrl.value = url;
      });
    });
    onBeforeUnmount(() => {
      mitt.off("lightBox");
    });

    //=============    close    ============//
    const isActive = ref(false);
    const close = () => {
      isActive.value = false;
    };

    //============ control ===============//
    const rotateCount = ref(0);
    const scaleCount = ref(1);
    const control = (el) => {
      let target = el.target;
      let control;
      if (target.nodeName === "I") {
        control = target.parentNode.dataset.control;
      } else if (target.nodeName === "BUTTON") {
        control = target.dataset.control;
      }

      switch (control) {
        case "scaleDown":
          if (scaleCount.value > 0.7) {
            scaleCount.value -= 0.1;
          }

          break;
        case "scaleUp":
          if (scaleCount.value < 2.5) {
            scaleCount.value += 0.1;
          }

          break;
        case "rotate-left":
          rotateCount.value -= 1;

          break;
        case "rotate-right":
          rotateCount.value += 1;
          break;
      }
    };
    return {
      //active
      imgUrl,
      isActive,
      close,
      //control
      control,
      rotateCount,
      scaleCount,
    };
  },
};
</script>
